import {Auth} from 'aws-amplify';
import $ from "jquery";
import { Modal } from 'bootstrap'
var bootstrap = require('bootstrap');
import './bootstrap-show-toast'
import 'datatables.net-bs5';

export function ParseJwt(token) {
    console.log('In ParseJwt function');
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
}

export function resetForm($form) {
    try{
        $form.find('input:text, input:password, input:file, select, textarea').val('');
        $form.find('input:radio, input:checkbox').removeAttr('checked').removeAttr('selected');
        // try remove the array selects
        $form.find("ul").empty()
        // set the remove select btn to disabled
        $(".btn.btn-default._jsonform-array-deletelast").addClass("disabled")
        
        if($form.attr("id") == "csvOptionsModalForm"){
            $("#csvPatternName").attr("disabled", true)
            $("#csvStitchName").attr("disabled", true)
            $("#csvFormStitchRefCopyBtn").attr("disabled", true)
            $("#delimiterSelect").attr("disabled", true)
            $("#textQualifierSelect").attr("disabled", true)
        }
        
    }catch(e){}
}


export function addBtnSpinner(el) {  
    let text  = $(el).html()
    $(el).html( '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>' + text)
}

export function removeBtnSpinner(el) {  
    let span = el + " span"
    $(span).remove()
}

export function parseURL(url) {
    var a =  document.createElement('a');
    a.href = url;
    return {
        source: url,
        protocol: a.protocol.replace(':',''),
        host: a.hostname,
        port: a.port,
        query: a.search,
        hash: a.hash.replace('#','')
    };
}


export function isfolder(path) {
    return path.endsWith('/');
}

export function bytesConversion(bytes){
    var count=0;
    var units = ['B', 'KB', 'MB', 'GB', 'TB']
    while(bytes / 1024 > 1) {
        bytes = bytes/1024;
        count++;
    }
    return "" + Math.round(bytes) + " " + units[count];
}

export function getFormData(form) {
    var formData = new FormData(form);
    console.debug(Object.fromEntries(formData));
    return Object.fromEntries(formData)
}


// show error modal
export function makeErrorMessage(error){
    let title = "Error";
    let message = "Error sending request: ";
    console.debug(JSON.stringify(error));
    try{
        // duplicate name    
        if(JSON.stringify(error.response.data).includes("duplicate key value violates") ){
            message += "An object with this name already exists. Please use a different name.";
        }
        else{
            message += error.response.data;
        }
    }
    catch (e) {
        message = error;
    }
    $('#loading-modal').addClass('hidden');
    $("#errorModalTitle").text(title);
    $("#errorModalText").text(message);
    Modal.getOrCreateInstance('#errorModal').toggle();
}

export function alertUser(message) {
    // $('#sqsMessageArea', window.parent.document).append('<div class="row w-100" style="height:fit-content; width:fit-content;"><div class="alert alert-primary alert-dismissable fade show w-100 p-2 px-3 mb-1" style="background: #264EAE;color:white;">' + message +'<button type="button" class="position-absolute btn-close btn-close-white float-right" style="right:2%;top:10%;" aria-label="Close" data-bs-dismiss="alert"></button></div></div>');
    
    bootstrap.showToast({
        body: message, toastClass: "toast-sheetloom", closeButtonClass: "btn-close-white", delay: Infinity, position: "bottom-0 end-0",
    })

}


// check pattern/stich names are valid for named ranges 
export function nameValidation(name){
    name = name.toLowerCase()
    const exp = /^(?!.*[ .,])[A-Za-z0-9_]{1}[A-Za-z0-9_.]*$/;
    if ( !exp.test(name) ) {
        const errorMessage = "Name cannot include spaces, special characters (except '_'), or be a keyword; 'path', 'id_token', 'access_token', 'expires_in', 'token_type'";
        alert(errorMessage);
        console.error(errorMessage);
        return true; // bad name
    }
    return false; // good name
}

export function removeFormRow(e){
    console.debug("form row: ", e)
    
    if ($(e).closest('div').find('select').find(":selected").text() == "default") {
        console.log($(e).closest('ul').children('li').length)
        if ($(e).closest('ul').children('li').length > 1) {
            console.log("More than one!")
            e.parentElement.parentElement.parentElement.remove();
        }
        else{
            alertUser("The group default cannot be removed!");
            $.fn.shake = function(interval = 200){
                this.css('border-width', '5px');
                this.css('border-color', 'red');
                this.css('transition', 'all ' + (interval / 100).toString() + 's');
                setTimeout(() => {
                    this.css('transform', 'translateX(-50%)');
                }, interval * 0);
                setTimeout(() => {
                    this.css('transform', 'translateX(50%)');
                }, interval * 1);
                setTimeout(() => {
                    this.css('transform', 'translateX(-25%)');
                }, interval * 2);
                setTimeout(() => {
                    this.css('transform', 'translateX(25%)');
                }, interval * 3);
                setTimeout(() => {
                    this.css('transform', 'translateX(-7%)');
                }, interval * 4);
                setTimeout(() => {
                    this.css('transform', 'translateX(0%)');
                    this.css('border-color', '');
                    this.css('border-width', '');
                }, interval * 5);
            };
            console.log($(e).closest('div').find('select').html);
            $(e).closest('div').find('select').shake();
        }
    }
    else {
        e.parentElement.parentElement.parentElement.remove();
    }
}

// For events that need to run after the tables fully load or get refreshed
export function clickEvents(table, page, tableObj, dblclickid) {
    $("#" + table + " thead tr").find('th:first').addClass('toggle-all');

    if (dblclickid) {
        $("#" + table + " tbody").on("dblclick", "tr", function(event) {
            let value = $(this).find('.select-checkbox div').attr("id");
            console.debug(value);
            $("#currentId").data("id", value).trigger('change');
        });
    }

    tableObj.on( 'user-select', function ( e, dt, type, cell, originalEvent ) {
        // $(this).off("user-select");
        var $elem = $(originalEvent.target); // get element clicked on
        var tag = $elem[0].nodeName.toLowerCase(); // get element's tag name

        console.debug($elem);
        console.debug(tag);

        // if (!$elem.closest("div.dropdown").length) {
        //     e.preventDefault();
        // }

        if (tag === "i" || tag === "a" || tag === "button") {
            e.preventDefault();
        }
    } )

    $("#main").on('click', '.toggle-all', function() {
        let table = $(this);
        console.log(table.closest("tr").hasClass("selected"))
        if(table.closest("tr").hasClass("selected")){
            table.closest("tr").removeClass("selected");
            tableObj.rows().deselect();
         }
         else {
            table.closest("tr").addClass("selected");
            tableObj.rows().select();
         }
        
    }); 

    tableObj.on( 'select.dt deselect.dt',  function (evtObj) {
        var all = tableObj.rows().nodes().length;
        var sel = tableObj.rows(".selected").nodes().length;
        
        console.log(all, sel);
      
        if(all === sel){
            $(".toggle-all").closest("tr").addClass("selected");
        }
        else{
            $(".toggle-all").closest("tr").removeClass("selected");
        }

        if(sel == 1){
            console.debug('1 selected')
            $("#"+page+"EditButton").removeClass("hidden");
            $("#"+page+"DeleteButton").removeClass("hidden");
        }
        else if(sel > 1){
            console.debug('many selected')
            $("#"+page+"EditButton").addClass("hidden");
            $("#"+page+"DeleteButton").removeClass("hidden");
        }
        else{
            console.debug('none selected')
            $("#"+page+"EditButton").addClass("hidden");
            $("#"+page+"DeleteButton").addClass("hidden");
        }

        if(page == "explorer"){
            if(sel == 1){
                $("#"+page+"WeaveButton").removeClass("hidden");
            }
            else if(sel > 1){
                console.debug('many selected')
                $("#"+page+"WeaveButton").addClass("hidden");
            }
            else{
                console.debug('none selected')
                $("#"+page+"WeaveButton").addClass("hidden");
            }
        }
    
    } );
}

export function handleEvals(nonce) {
    window.Function = function () {
        var renderNode = document.createElement("script"),
            len = arguments.length,
            source = arguments[len-1],
            args = [];

        if ( 1 < len ) {
            for ( var i=0; i<(len-1); i++ ) {
                args.push(arguments[i]);
            }
        }

        renderNode.text = "function __ifYouAbsolutelyMustUseIt() { return function("+args.join(", ")+") {" + source + "}}";
        renderNode.setAttribute('nonce', nonce);

        document.head.appendChild(renderNode).parentNode.removeChild(renderNode);
        return __ifYouAbsolutelyMustUseIt();
    };
}

export function oneOffClicks() {
    $("#expired-modal-btn").on("click", async function (event) {
        $("#main").css({"visibility": "hidden"});
        await Auth.signOut();
        window.localStorage.clear();
    });
}

// replace 'undefined' values with empty string
// replace bool with icon
export function replaceUndefinedValues(rows){
    for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        for (let j = 0; j < row.length; j++) {
            const cell = row[j];
            if(cell == undefined || cell == null){
                if(cell === false){
                    rows[i][j] = '<i class="bi bi-x-circle"></i>'
                }
                rows[i][j] = ""
            }
            if(cell === true || cell === "true"){
                rows[i][j] = '<i class="bi bi-check-circle"></i>'
            }
            else if(cell === false || cell === "false"){
                rows[i][j] = '<i class="bi bi-x-circle"></i>'
            }
        }
    }
    return rows
}

export function setDropdowns(){
    $("form").find(".controls:has('select')").each(function(i, e) {
        if (!($(e).hasClass("icon-div")) && $(e).children(":first-child").is("select")) {
            $(e).addClass("icon-div");
            $(e).append('<span class="icon"><i class="bi bi-chevron-down"></i></span>');
        }
    });
}

export function clearButtons(page) {
    if (!$("#" + page + "EditButton").hasClass("hidden")) {
        $("#" + page + "EditButton").toggleClass("hidden");
    }
    if (!$("#" + page + "DeleteButton").hasClass("hidden")) {
        $("#" + page + "DeleteButton").toggleClass("hidden");
    }
}